

import { useNavigate } from 'react-router-dom';
import './ServiceBlock.css';
const ServiceBlock = ({ title, serviceName, icon ,name}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Construct the page path based on the icon prop
    const pagePath = `/${name}`;
    // Navigate to the page path
    navigate(pagePath);
  };

  return (
    <div className="col-lg-4 service-block-one">
      <div className="inner-box hvr-float-shadow">
        <h5>{title}</h5>
        <h2>{serviceName}</h2>
        <a href="" className="read-more" onClick={handleClick}>
          read more +
        </a>
        <div className="icon">
          <span className="sframe">
            <img src={icon} alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ServiceBlock;
  