import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js/auto';
import mapboxgl from 'mapbox-gl';
import AdminMenu from '../components/Admin/AdminMenu/AdminMenu'; // Assuming you have an AdminMenu component

Chart.register(...registerables);

const VisitorDashboard = () => {
  const [visitorData, setVisitorData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Visitors',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Set background color
      },
    ],
  });
  const [visitorMap, setVisitorMap] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch visitor data from IP Geolocation API
    const fetchVisitorData = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Prepare the data for the chart
        const labels = [`${data.country_name}, ${data.city}`];
        const visitorCounts = [1]; // Assuming one visitor for the example

        setVisitorData({
          labels,
          datasets: [
            {
              label: 'Visitors',
              data: visitorCounts,
              backgroundColor: 'rgba(75, 192, 192, 0.6)', // Set background color
            },
          ],
        });

        // Initialize the Mapbox map with the visitor location
        initMap(data);
      } catch (error) {
        console.error('Error fetching visitor data:', error);
        setError('Could not fetch visitor data.');
      }
    };

    fetchVisitorData();
  }, []);

  const initMap = (data) => {
    mapboxgl.accessToken = 'YOUR_MAPBOX_ACCESS_TOKEN'; // Replace with your actual Mapbox access token
    const map = new mapboxgl.Map({
      container: 'visitor-map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [data.longitude, data.latitude], // Center the map on the visitor's location
      zoom: 10,
    });

    new mapboxgl.Marker()
      .setLngLat([data.longitude, data.latitude])
      .addTo(map);

    setVisitorMap(map);
  };

  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          <AdminMenu />
        </div>
        <div className="col-md-9 admin-right-side">
          <h2>Visitor Dashboard</h2>
          {error && <p className="error">{error}</p>}
          <div id="visitor-map" style={{ height: '400px' }} />
          <Bar data={visitorData} />
        </div>
      </div>
    </div>
  );
};

export default VisitorDashboard;