import React, { useState, useEffect } from 'react';
import newsList from '../../../../services/news.service'
import AdminMenu from  '../../../components/Admin/AdminMenu/AdminMenu'
const api_url = 'https://your-api-url.com';
const NewsTable = () => {
  const [news, setNews] = useState([]);
  const [token, setToken] = useState('your-access-token');
  const [editingNewsItem, setEditingNewsItem] = useState(null); // New state variable to track the news item being edited
  const [editedNewsItem, setEditedNewsItem] = useState(null); // New state variable to store the edited news item

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const allNews = await newsList.getAllNews(token);
        setNews(allNews.data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, [token]);

  const handleEdit = (newsItem) => {
    setEditingNewsItem(newsItem); // Set the news item being edited
    setEditedNewsItem({ ...newsItem }); // Create a copy of the news item to be edited
  };

  const handleSaveEdit = async () => {
    try {
      console.log('Updating news item:', editedNewsItem);
      const response = await newsList.updateNews(
        editedNewsItem.news_event_id,
        editedNewsItem,
        editedNewsItem.image,
        token
      );
      if (response.status === 200) {
        // Update the news item in the state
        setNews(
          news.map((item) =>
            item.news_event_id === editedNewsItem.news_event_id ? response.data : item
          )
        );
        setEditingNewsItem(null); // Reset the editing state
        setEditedNewsItem(null); // Reset the edited news item
        console.log('News item updated successfully.');
      } else {
        console.error('Error updating news item:', response.status);
      }
    } catch (error) {
      console.error('Error updating news item:', error);
    }
  };

  const handleCancel = () => {
    setEditingNewsItem(null); // Reset the editing state
    setEditedNewsItem(null); // Reset the edited news item
  };

  const handleDelete = async (newsItem) => {
    // Existing handleDelete function
    try {
      console.log('Deleting news item:', newsItem.news_event_id);
      const response = await newsList.deleteNews(newsItem.news_event_id, token);
      if (response.status === 200) {
        // Remove the deleted news item from the state
        setNews(news.filter((item) => item.news_event_id !== newsItem.news_event_id));
        console.log('News item deleted successfully.');
      } else {
        console.error('Error deleting news item:', response.status);
      }
    } catch (error) {
      console.error('Error deleting news item:', error);
    }
  };

  return (
    <>
    <div className="container-fluid admin-pages">
    <div className="row">
      <div className="col-md-3 admin-left-side">
        <AdminMenu />
      </div>
      <div className="col-md-9 admin-right-side">
    <div style={{ minHeight: '100vh', marginTop: '200px' }}>
      <h1>News</h1>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Content</th>
              <th>Author</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {news.map((newsItem) => (
              <tr key={newsItem.news_event_id}>
                <td>
                  {editingNewsItem && editingNewsItem.news_event_id === newsItem.news_event_id ? (
                    <input
                      type="text"
                      value={editedNewsItem.title}
                      onChange={(e) =>
                        setEditedNewsItem({ ...editedNewsItem, title: e.target.value })
                      }
                    />
                  ) : (
                    newsItem.title
                  )}
                </td>
                <td>
                  {editingNewsItem && editingNewsItem.news_event_id === newsItem.news_event_id ? (
                    <textarea
                      value={editedNewsItem.content}
                      onChange={(e) =>
                        setEditedNewsItem({ ...editedNewsItem, content: e.target.value })
                      }
                    />
                  ) : (
                    newsItem.content
                  )}
                </td>
                <td>
                  {editingNewsItem && editingNewsItem.news_event_id === newsItem.news_event_id ? (
                    <input
                      type="text"
                      value={editedNewsItem.author}
                      onChange={(e) =>
                        setEditedNewsItem({ ...editedNewsItem, author: e.target.value })
                      }
                    />
                  ) : (
                    newsItem.author
                  )}
                </td>
                <td>
                  {editingNewsItem && editingNewsItem.news_event_id === newsItem.news_event_id ? (
                    <div>
                      <button className="btn btn-primary" onClick={handleSaveEdit}>
                        Save
                      </button>
                      <button className="btn btn-secondary" onClick={handleCancel}>
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button className="btn btn-primary" onClick={() => handleEdit(newsItem)}>
                        Edit
                      </button>
                      <button className="btn btn-danger" onClick={() => handleDelete(newsItem)}>
                        Delete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default NewsTable;