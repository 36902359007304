import React, { useState, useEffect } from 'react';
import './news.css';
import getAllNews  from '../../services/news.service';
import { useNavigate } from 'react-router-dom';
const LatestNewsTransition = () => {
  const navigate = useNavigate();
  const [newsItems, setNewsItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getAllNews.getAllNews();
        console.log(data);
        setNewsItems(data.data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % newsItems.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [newsItems]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };


  function handleNewsClick(newsItem) {
  
    navigate('/news', { state: newsItem });
    console.log(newsItem)
  }
  return (
    <div className='shnews'>
      {newsItems.length > 0 && (
        <div key={newsItems[currentIndex].news_event_id} className='shortnews'>
          <h2 className='latest-news-label'>Latest News</h2>
          <a
            className=''
            onClick={() => handleNewsClick(newsItems[currentIndex])}
          >
            {newsItems[currentIndex].title}{' '}
            <span className='news-date'>
              {formatDate(newsItems[currentIndex].created_at)}
            </span>
          </a>
        </div>
      )}
    </div>
  );
};




export default LatestNewsTransition;