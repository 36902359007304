import React from 'react';
import logo from '../../../assets/images/Logo.png';
function HeaderLogo() {
  return (
    <div className="logo-box">
      <div className="logo"><a href="/"><img src={logo} alt="" /></a></div>
    </div>
  );
}

export default HeaderLogo;