import React, { useState } from 'react';
import createAnnouncement   from '../../../../services/announcement.service';
import AdminMenu from '../../../components/Admin/AdminMenu/AdminMenu';
const AddAnnouncementForm = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [image, setImage] = useState(null);
  const [loggedInEmployeeToken, setLoggedInEmployeeToken] = useState('your_token_here');


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      title,
      content,
      author
    };
  
    try {
      await createAnnouncement.createAnnouncement(formData, image, loggedInEmployeeToken);
      // Reset the form fields
      setTitle('');
      setContent('');
      setAuthor('');
      setImage('');
    } catch (error) {
      console.error('Error creating news:', error);
    }
  };


  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="container-fluid admin-pages">
        <div className="row">
          <div className="col-md-3 admin-left-side">
            <AdminMenu />
          </div>
          <div className="col-md-9 admin-right-side">
            
    <form onSubmit={handleSubmit} className='addnews'>
    <h1>Add Announcement</h1>
      <div>
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="content">Content:</label>
        <textarea
          id="content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        ></textarea>
      </div>
      <div>
        <label htmlFor="author">Author:</label>
        <input
          type="text"
          id="author"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="image">Image:</label>
        <input
          type="file"
          id="image"
          onChange={handleImageChange}
          required
        />
      </div>
      <button type="submit">Create News</button>
    </form>
    </div>
    </div>
    </div>
  );
};

export default AddAnnouncementForm;