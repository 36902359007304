import React from 'react'

const Footer = () => {
  return (
    <footer className="main-footer">
    {/* Upper Box */}
    <div className="upper-box">
      <div className="auto-container">
        <div className="row no-gutters">
          {/* Footer Info Box */}
          <div className="footer-info-box col-md-4 col-sm-6 col-xs-12">
            <div className="info-inner">
              <div className="content">
                <div className="icon">
                  <span className="flaticon-pin"></span>
                </div>
                <div className="text">
                  Addis Ababa, <br /> Ethiopia
                </div>
              </div>
            </div>
          </div>

          {/* Footer Info Box */}
          <div className="footer-info-box col-md-4 col-sm-6 col-xs-12">
            <div className="info-inner">
              <div className="content">
                <div className="icon">
                  <span className="flaticon-email"></span>
                </div>
                <div className="text">
                  Email us : <br />
                  <a href="mailto:contact.contact@autorex.com">info@admasuniversity.edu.et</a>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Info Box */}
          <div className="footer-info-box col-md-4 col-sm-6 col-xs-12">
            <div className="info-inner">
              <div className="content">
                <div className="icon">
                  <span className="flaticon-phone"></span>
                </div>
                <div className="text">
                  Call us on : <br />
                  <p> 0115-50-88-08/10 Fax: 0115-50-89-01</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Widgets Section */}
    <div className="widgets-section">
      <div className="auto-container">
        <div className="widgets-inner-container">
          <div className="row clearfix">
            {/* Footer Column */}
            <div className="footer-column col-lg-4">
              <div className="widget widget_about">
                <div className="logo">
                  <a href="index.html">
                    <img src="assets/images/logo-two.png" alt="" />
                  </a>
                </div>
                <div className="text">
                Top and Number one in All over Ethiopia
                Admass University envisions being the most reputable private higher education institution in terms of outstanding quality education, research, training, and community development services in East Africa by 2020.
                </div>
              </div>
            </div>

            {/* Footer Column */}
            <div className="footer-column col-lg-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="widget widget_links">
                    <h4 className="widget_title">Usefull Links</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="https://lms.admasuniversity.edu.et/login/index.php" target='_blank'>LMS</a></li>
                        <li><a href="http://5.77.63.215:8080/" target='_blank'>Library System</a></li>
                        <li><a href="contact.html">Contact Us</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="widget widget_links">
                    <h4 className="widget_title">Academic Programs</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li><a href="/postgrad">School of Postgraduate Studies</a></li>
                        <li><a href="/undergrad">Undergraduate Academic Programs</a></li>
                        <li><a href="/distance">Distance Learning Program</a></li>
                        <li><a href="/tvet">TVET Programs</a></li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer Column */}
            <div className="footer-column col-lg-4">
              <div className="widget widget_newsletter">
                <h4 className="widget_title">Follow Us</h4>
                
                <div className="newsletter-form">
                
                </div>
                <ul className="social-links">
                  <li>
                    <a href="https://www.facebook.com/AdmasUni">
                      <span className="fab fa-facebook-f"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fab fa-linkedin-in"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fab fa-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fab fa-google-plus-g"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Footer Bottom */}
    <div className="auto-container">
      <div className="footer-bottom">
        <div className="copyright-text">
          © Copyright <a href="#">Admas University</a> 2024 . All right reserved.
        </div>
        <div className="text" style={{fontSize:'8px'}}>By YoYeSoft</div>
      </div>
    </div>
  </footer>
  )
}

export default Footer