// Import the necessary components 
import React, { useState, useEffect } from "react";
import { Table, Button, Pagination } from 'react-bootstrap';
// Import the auth hook  
import { useAuth } from "../../../../context/AuthContext";
// Import the date-fns library 
import { format } from 'date-fns'; // To properly format the date on the table 
// Import the getAllEmployees function  
import employeeService from "../../../../services/employee.service";
import {FaTrashAlt} from 'react-icons/fa'
import { FaEdit } from "react-icons/fa";
import './employeeList.css'
import AdminMenu from "../AdminMenu/AdminMenu";

// Create the EmployeesList component 
const EmployeesList = () => {
  // Create all the states we need to store the data
  // Create the employees state to store the employees data  
  const [employees, setEmployees] = useState([]);
  // A state to serve as a flag to show the error message 
  const [apiError, setApiError] = useState(false);
  // A state to store the error message 
  const [apiErrorMessage, setApiErrorMessage] = useState(null);

  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  // Add states for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);

  // To get the logged in employee token
  const { employee } = useAuth();
  let token = null; // To store the token 
  if (employee) {
    token = employee.employee_token;
  }

  useEffect(() => {
    // Call the getAllEmployees function 
    const allEmployees = employeeService.getAllEmployees(token);
    console.log(allEmployees)
    allEmployees.then((res) => {
      if (!res.ok) {
        console.log(res.status);
        setApiError(true);
        if (res.status === 401) {
          setApiErrorMessage("Please login again");
        } else if (res.status === 403) {
          setApiErrorMessage("You are not authorized to view this page");
        } else {
          setApiErrorMessage("Please try again later");
        }
      }
      return res.json()
    }).then((data) => {
      if (data.data.length !== 0) {
        setEmployees(data.data)
      }

    }).catch((err) => {
      // console.log(err);
    })
  }, []);

  const handleDeleteEmployee = async (employeeId) => {
    try {
      setEmployeeIdToDelete(employeeId);
      const response = await employeeService.deleteEmployee(employeeId, token);
      if (response.ok) {
        // Remove the deleted employee from the state
        setEmployees(employees.filter((employee) => employee.employee_id !== employeeId));
      } else {
        // Handle the error
        setApiError(true);
        setApiErrorMessage("Error deleting employee. Please try again later.");
      }
    } catch (error) {
      // Handle the error
      setApiError(true);
      setApiErrorMessage("Error deleting employee. Please try again later.");
    } finally {
      setEmployeeIdToDelete(null);
    }
  };

  // Calculate the index of the first and last items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employees.slice(indexOfFirstItem, indexOfLastItem);

  // Change the current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
     <div className="container-fluid admin-pages">
        <div className="row">
          <div className="col-md-3 admin-left-side">
            <AdminMenu />
          </div>
          <div className="col-md-9 admin-right-side">
      {apiError ? (
        <section className="contact-section">
          <div className="auto-container">
            <div className="contact-title">
              <h2>{apiErrorMessage}</h2>
            </div >
          </div>
        </section>
      ) : (
        <>
          <section className="contact-section">
            <div className="auto-container">
              <div className="contact-title">
                <h2>Employees</h2 >
              </div >
              < Table striped bordered hover >
                <thead>
                  <tr>
                    <th>Active</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Added Date</th>
                    <th>Role</th>
                    <th>Edit/Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((employee) => (
                    <tr key={employee.employee_id}>
                      <td>{employee.employee_active_status? "Yes" : "No"}</td>
                      <td>{employee.employee_first_name}</td>
                      <td>{employee.employee_last_name}</td>
                      <td>{employee.employee_email}</td>
                      <td>{employee.employee_phone}</td>
                      <td>{employee.created_at}</td>
                      <td>{employee.company_role_name}</td>
                      <td>
                        <div className="edit-delete-icons">
                          <FaEdit />
                          | {employeeIdToDelete === employee.employee_id
                          ? (
                            <span>Deleting...</span>
                          ) : (
                            <FaTrashAlt
                              onClick={() => handleDeleteEmployee(employee.employee_id)}
                              role="button"
                              tabIndex="0"
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table >
              {/* Add pagination component */}
              <Pagination>
                {Array.from({ length: Math.ceil(employees.length / itemsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div >
          </section >
        </>
      )}
      </div>
      </div>
      </div>
    </>
  );
}

// Export the EmployeesList component 
export default EmployeesList;