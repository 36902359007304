import { Routes,Route } from "react-router";
import Home from './markup/pages/Home'
import Login from './markup/pages/Login'
import AddEmployee from './markup/pages/admin/AddEmployee'

//Import the css files
import './assets/css/style.css'
import './assets/css/bootstrap.css'
import './assets/css/responsive.css'
import './assets/css/color.css'
import './assets/css/custom.css'

import Header from './markup/components/Header/Header'
import Footer from './markup/components/Footer/Footer'
import Contact from './markup/pages/Contact'
import PresidentMessage from "./markup/components/PresidentMessage";
import History from './markup/pages/History';
import Team from './markup/components/TeamSection';
import News from './markup/pages/News';
import AboutSection from "./markup/components/AboutSection";
// import BlogDetail from "./markup/pages/BlogDetail";
import VacancyForm from './markup/pages/VacancyForm'
import Postgrad from "./markup/pages/Postgrad";
import Undergrad from "./markup/pages/Undergrad";
import Distance from "./markup/pages/Distance";
import TVET from "./markup/pages/TVET";
import Unauthorized from "./markup/pages/Unauthorized";
import PrivateAuthRoute from "./markup/components/Auth/PrivateAuthRoute";
import AddNews from "./markup/components/Admin/AddNews/NewsForm";
import Mekele from "./markup/pages/campuses/Mekele";
import Mekanisa from "./markup/pages/campuses/Mekanisa";
import Megenagna from "./markup/pages/campuses/Megenagna";
import Kality from "./markup/pages/campuses/Kality";
import Bisheftu from "./markup/pages/campuses/Bisheftu";
import Meskel from "./markup/pages/campuses/Meskel";
import Olympia from "./markup/pages/campuses/Olompiya";
import Training from "./markup/pages/Training";
import BlogDetails from './markup/components/BlogDetails';
import Events from "./markup/components/Events"
import VacancyDetails from "./markup/pages/VacancyDetails";
import NewsTable from "./markup/components/Admin/AddNews/ListNewsForm";
import EmployeesList from "./markup/components/Admin/AddEmployeeForm/EmployeesList";
import VisitorDashboard from "./markup/pages/VisitorDashboard"
import UniversityInfo from "./markup/pages/UniversityInfo"
import AllAnouncement from "./markup/pages/AllAnouncement";
import AddAnnouncementForm from "./markup/components/Admin/Announcement/AddAnnouncementForm"
import ListAnnouncementForm from "./markup/components/Admin/Announcement/ListAnnouncementForm";
import ScrollToTopButton from "./markup/pages/ScrollToTopButton";

function App() {
  return (
   
    <>

    <Header />
       <Routes>
      
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/president-message" element={<PresidentMessage />} />
      <Route path="/history" element={<History />} />
      <Route path="/team" element={<Team />} />
      <Route path="/news" element={<News />} />
      <Route path="/about" element={<AboutSection />} />
      <Route path="/universityinfo" element={<UniversityInfo />} />
      <Route path="/postgrad" element={<Postgrad />} />
      <Route path="/undergrad" element={<Undergrad />} />
      <Route path="/distance" element={<Distance />} />
      <Route path="/tvet" element={<TVET />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/mekele" element={<Mekele/>  } />
      <Route path="/mekanisa" element={<Mekanisa/>  } />
      <Route path="/megenagna" element={<Megenagna/>  } />
      <Route path="/kality" element={<Kality/> } />
      <Route path="/bisheftu" element={<Bisheftu/> } />
      <Route path="/meskel" element={<Meskel/> } />
      <Route path="/olympia" element={<Olympia/> } />
      <Route path="/training" element={<Training />} />
      <Route path="/allannouncement" element={<AllAnouncement />} />
      <Route path="/blogdetails" element ={<BlogDetails/>} />
      <Route path="/events" element ={<Events/>} />
      <Route path="/vacancy" element ={<VacancyDetails/>} />
      <Route path="/scroll" element ={<ScrollToTopButton/>} />
      

      <Route path="/admin/add-employee"
          element={
            <PrivateAuthRoute roles={[3]}>
              <AddEmployee />
            </PrivateAuthRoute>
          } />

          <Route path="/admin/add-news"
          element={
            <PrivateAuthRoute roles={[3]}>
              <AddNews />
            </PrivateAuthRoute>
          } />

          <Route path="/admin/add-vacancy"
          element={
            <PrivateAuthRoute roles={[1,2,3]}>
              <VacancyForm />
            </PrivateAuthRoute>
          } />
          <Route path="/admin/list-news"
          element={
            <PrivateAuthRoute roles={[1,2,3]}>
              <NewsTable />
            </PrivateAuthRoute>
          } />
          <Route path="/admin/employees"
          element={
            <PrivateAuthRoute roles={[1,2,3]}>
              <EmployeesList />
            </PrivateAuthRoute>
          } />
          <Route path="/visitor/dashboard"
          element={
            <PrivateAuthRoute roles={[0,1,2,3]}>
              <VisitorDashboard />
            </PrivateAuthRoute>
          } />
          <Route path="/admin/add-announcement"
          element={
            <PrivateAuthRoute roles={[1,2,3]}>
              <AddAnnouncementForm />
            </PrivateAuthRoute>
          } />
          <Route path="/admin/list-announcement"
          element={
            <PrivateAuthRoute roles={[1,2,3]}>
              <ListAnnouncementForm />
            </PrivateAuthRoute>
          } />

    </Routes>
    <Footer />
    </>
 
  );
}

export default App;
