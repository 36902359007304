// import React, { useState, useEffect, useRef } from 'react';

// const useIntersection = (ref, rootMargin = '0px') => {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setIsVisible(entry.isIntersecting);
//       },
//       { rootMargin }
//     );

//     if (ref.current) {
//       observer.observe(ref.current);
//     }

//     return () => {
//       observer.unobserve(ref.current);
//     };
//   }, [ref, rootMargin]);

//   return isVisible;
// };

// const UniversityInfo = ({ count, programs, campuses }) => {
//   const [currentCount, setCurrentCount] = useState(0);
//   const [currentPrograms, setCurrentPrograms] = useState(0);
//   const [currentCampuses, setCurrentCampuses] = useState(0);
//   const [isCountVisible, setIsCountVisible] = useState(false);
//   const componentRef = useRef(null);
//   const isInViewport = useIntersection(componentRef, '-50px');

//   useEffect(() => {
//     if (isInViewport) {
//       const interval = setInterval(() => {
//         if (currentCount < 30000 || currentPrograms < 30 || currentCampuses < 10) {
//           if (currentCount < 30000) {
//             setCurrentCount((prevCount) => prevCount + 1);
//           }
//           if (currentPrograms < 30) {
//             setCurrentPrograms((prevPrograms) => prevPrograms + 1);
//           }
//           if (currentCampuses < 10) {
//             setCurrentCampuses((prevCampuses) => prevCampuses + 1);
//           }
//           setIsCountVisible(true);
//           setTimeout(() => {
//             setIsCountVisible(false);
//           }, 1); // Hide the count for 1 ms (1 microsecond)
//         } else {
//           clearInterval(interval);
//         }
//       }, .01); // Run the interval every 10 ms

//       return () => clearInterval(interval);
//     }
//   }, [count, programs, campuses, currentCount, currentPrograms, currentCampuses, isInViewport]);

//   return (
//     <div className="university-info" ref={componentRef}>
    //   <div className="count-item">
    //   <span>{currentCount} +</span>
    //     <div className="count-label">Graduated Students:</div>
      
    //     <div className={`animated-count ${isCountVisible ? 'visible' : ''}`}>
       
    //     </div>
    //   </div>
    //   <div className="count-item">
    //   <span> {currentPrograms}+</span>
    //     <div className="count-label">Accrediated Programs:</div>
 
    //     <div className={`animated-count ${isCountVisible ? 'visible' : ''}`}>
          
    //     </div>
    //   </div>
    //   <div className="count-item">
    //   <span> {currentCampuses}+</span>
    //     <div className="count-label">Campuses:</div>
       
    //     <div className={`animated-count ${isCountVisible ? 'visible' : ''}`}>
         
    //     </div>
    //   </div>
//     </div>
//   );
// };

// export default UniversityInfo;

import React, { useState, useEffect, useRef } from 'react';

const useIntersection = (ref, rootMargin = '0px') => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, rootMargin]);

  return isVisible;
};

const UniversityInfo = ({ count, programs, campuses }) => {
  const [currentCount, setCurrentCount] = useState(0);
  const [currentPrograms, setCurrentPrograms] = useState(0);
  const [currentCampuses, setCurrentCampuses] = useState(0);
  const [isCountVisible, setIsCountVisible] = useState(false);
  const componentRef = useRef(null);
  const isInViewport = useIntersection(componentRef, '-50px');

  useEffect(() => {
    let interval;
    if (isInViewport) {
      interval = setInterval(() => {
        if (currentCount < 100000 || currentPrograms < 30 || currentCampuses < 10) {
          if (currentCount < 100000) {
            setCurrentCount((prevCount) => prevCount + 1);
          }
          if (currentPrograms < 30) {
            setCurrentPrograms((prevPrograms) => prevPrograms + 1);
          }
          if (currentCampuses < 10) {
            setCurrentCampuses((prevCampuses) => prevCampuses + 1);
          }
          setIsCountVisible(true);
          setTimeout(() => {
            setIsCountVisible(false);
          }, 1); // Hide the count for 1 ms (1 microsecond)
        } else {
          clearInterval(interval);
        }
      }, 10); // Run the interval every 10 ms
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (componentRef.current) {
        const observer = new IntersectionObserver(() => {}, { rootMargin: '-50px' });
        observer.unobserve(componentRef.current);
      }
    };
  }, [count, programs, campuses, currentCount, currentPrograms, currentCampuses, isInViewport]);

  return (
    <div className="university-info" ref={componentRef}>
      {/* Rest of the component */}
      <div className="count-item">
      <span>{currentCount} +</span>
        <div className="count-label">Graduated Students:</div>
      
        <div className={`animated-count ${isCountVisible ? 'visible' : ''}`}>
       
        </div>
      </div>
      <div className="count-item">
      <span> {currentPrograms}+</span>
        <div className="count-label">Accrediated Programs:</div>
 
        <div className={`animated-count ${isCountVisible ? 'visible' : ''}`}>
          
        </div>
      </div>
      <div className="count-item">
      <span> {currentCampuses}+</span>
        <div className="count-label">Campuses:</div>
       
        <div className={`animated-count ${isCountVisible ? 'visible' : ''}`}>
         
        </div>
      </div>
    </div>
  );
};

export default UniversityInfo;