

// import React, { useState } from 'react';
// import AdminMenu from '../components/Admin/AdminMenu/AdminMenu';
// import vacancry from '../../services/vacancy.service'
// import employeeService from '../../services/employee.service';

// //import the useAuth hook
// import { useAuth } from '../../context/AuthContext';
// const VacancyForm = () => {

//   let loggedInEmployeeToken ='';
//   //Destructure the auth hook and get the token
//   const {employee} = useAuth();
//   if(employee && employee.employee_token){
//     loggedInEmployeeToken = employee.employee_token;
//   }
//   const [formData, setFormData] = useState([]);


//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async(e) => {
//     e.preventDefault();
//     try {
//       // Call the createVacancy function
//       const response = await vacancry.createVacancy(formData, loggedInEmployeeToken);

//       if (response.ok) {
//         // Reset the form
//         setFormData({
//           title: '',
//           description: '',
//           location: '',
//           salary: '',
//           job_type: '',
//           requirements: '',
//           link: '',
//           posted_date: '',
//           end_date: '',
//         });

//         // Show a success message or redirect the user
//         alert('Vacancy created successfully!');
//       } else {
//         // Show an error message to the user
//         alert('Error creating vacancy. Please try again later.');
//       }
//     } catch (error) {
//       console.error('Error creating vacancy:', error);
//       // Show an error message to the user
//       alert('Error creating vacancy. Please try again later.');
//     }
//   };

//   return (
//     <>
//     <div className="container-fluid admin-pages">
//         <div className="row">
//           <div className="col-md-3 admin-left-side">
//             <AdminMenu />
//           </div>
//           <div className="col-md-9 admin-right-side">
    // <form onSubmit={handleSubmit} style={{marginTop:'200px' , minHeight:'100vh'}}>
    //   <div>
    //     <label htmlFor="title">Job Title</label>
    //     <input
    //       type="text"
    //       id="title"
    //       name="title"
    //       value={formData.title}
    //       onChange={handleInputChange}
    //       required
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="description">Job Description</label>
    //     <textarea
    //       id="description"
    //       name="description"
    //       value={formData.description}
    //       onChange={handleInputChange}
    //       required
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="location">Location</label>
    //     <input
    //       type="text"
    //       id="location"
    //       name="location"
    //       value={formData.location}
    //       onChange={handleInputChange}
    //       required
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="salary">Salary</label>
    //     <input
    //       type="text"
    //       id="salary"
    //       name="salary"
    //       value={formData.salary}
    //       onChange={handleInputChange}
    //       required
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="jobType">Job Type</label>
        // <select
        //   id="job_type"
        //   name="job_type"
        //   value={formData.job_type}
        //   onChange={handleInputChange}
        //   required
        // >
        //   <option value="">Select job type</option>
        //   <option value="fullTime">Full-Time</option>
        //   <option value="partTime">Part-Time</option>
        //   <option value="contract">Contract</option>
        //   <option value="internship">Internship</option>
        // </select>
    //   </div>
    //   <div>
    //     <label htmlFor="requirements">Requirements</label>
    //     <textarea
    //       id="requirements"
    //       name="requirements"
    //       value={formData.requirements}
    //       onChange={handleInputChange}
    //       required
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="applyLink">Apply Link</label>
    //     <input
    //       type="url"
    //       id="link"
    //       name="link"
    //       value={formData.link}
    //       onChange={handleInputChange}
    //       required
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="applyLink">Posted Date</label>
    //     <input
    //       type="date"
    //       id="posted_date"
    //       name="posted_date"
    //       value={formData.posted_date}
    //       onChange={handleInputChange}
    //       required
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="applyLink">End Date</label>
    //     <input
    //       type="date"
    //       id="end_date"
    //       name="end_date"
    //       value={formData.end_date}
    //       onChange={handleInputChange}
    //       required
    //     />
    //   </div>
    //   <button type="submit">Submit</button>
    // </form>
//     </div>
//     </div>
//     </div>
//     </>
//   );
// };

// export default VacancyForm;
import React, { useState } from 'react';
import AdminMenu from '../components/Admin/AdminMenu/AdminMenu';
import vacancry from '../../services/vacancy.service'
import employeeService from '../../services/employee.service';
import { useAuth } from '../../context/AuthContext';

const VacancyForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [salary, setSalary] = useState('');
  const [jobType, setJobType] = useState('');
  const [requirements, setRequirements] = useState('');
  const [link, setLink] = useState('');
  const [postedDate, setPostedDate] = useState('');
  const [endDate, setEndDate] = useState('');

  let loggedInEmployeeToken ='';
  const {employee} = useAuth();
  if(employee && employee.employee_token){
    loggedInEmployeeToken = employee.employee_token;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData ={
      title,
      description,
      location,
      salary,
      job_type: jobType,
      requirements,
      link,
      posted_date: postedDate,
      end_date: endDate,
    }

    vacancry.createVacancy(formData, loggedInEmployeeToken)
     .then((response) => {
        if (response.status === 200) {
          alert('Vacancy created successfully!');
          // Reset form fields
          setTitle('');
          setDescription('');
          setLocation('');
          setSalary('');
          setJobType('');
          setRequirements('');
          setLink('');
          setPostedDate('');
          setEndDate('');
        } else {
          alert('Error creating vacancy. Please try again later.');
        }
      })
     .catch((error) => {
        console.error('Error creating vacancy:', error);
        alert('Error creating vacancy. Please try again later.');
      });
  }

  return (
    <>
    <div className="container-fluid admin-pages">
        <div className="row">
          <div className="col-md-3 admin-left-side">
            <AdminMenu />
          </div>
          <div className="col-md-9 admin-right-side">
        
          <form onSubmit={handleSubmit} style={{marginTop:'200px' , minHeight:'100vh'}}>
          <h1 style={{textAlign:'center'}}>Add Vacancy</h1>
      <div>
        <label htmlFor="title">Job Title</label>
        <input
          type="text"
          id="title"
          name="title"
          value={title}
          onChange={(e)=> setTitle(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="description">Job Description</label>
        <textarea
          id="description"
          name="description"
          value={description}
          onChange={(e)=> setDescription(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="location">Location</label>
        <input
          type="text"
          id="location"
          name="location"
          value={location}
          onChange={(e)=> setLocation(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor='salary'>Salary</label>
        <input
        type="number"
          id="salary"
          name="salary"
          value={salary}
          onChange={(e)=>setSalary(e.target.value)}
          required
          />
      </div>
      <div>
  <label htmlFor="jobType">Job Type</label>
  <select
    id="job_type"
    name="job_type"
    value={jobType}
    onChange={(e) => setJobType(e.target.value)}
    required
  >
    <option value="">Select job type</option>
    <option value="fullTime">Full-Time</option>
    <option value="partTime">Part-Time</option>
    <option value="contract">Contract</option>
    <option value="internship">Internship</option>
  </select>
</div>

      <div>
        <label htmlFor="requirements">Requirements</label>
        <textarea
          id="requirements"
          name="requirements"
          value={requirements}
          onChange={(e)=> setRequirements(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="link">Apply Link</label>
        <input
          type="url"
          id="link"
          name="link"
          value={link}
          onChange={(e)=> setLink(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="posted_date">Posted Date</label>
        <input
          type="date"
          id="posted_date"
          name="posted_date"
          value={postedDate}
          onChange={(e)=> setPostedDate(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="end_date">End Date</label>
        <input
          type="date"
          id="end_date"
          name="end_date"
          value={endDate}
          onChange={(e)=> setEndDate(e.target.value)}
          required
        />
      </div>
      <button type="submit">Submit</button>
    </form>
    
    </div>
    </div>
    </div>
    </>
  );
};

export default VacancyForm;