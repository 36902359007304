import React from 'react'
import training from '../../assets/images/icons/training'
import trainingbanner from '../../assets/images/icons/trainingbanner'
import ScrollToTopButton from '../pages/ScrollToTopButton'

const Training = () => {
  return (
    <div style={{marginTop:'50px'}}>
        
      
    <div className="banner-slide">
          <img src={trainingbanner} alt="Banner 2" className="banner-image" />
          <div className="banner-content">
          <h1 style={{boxShadow:'10px 10px 5px 0 rgba(10,10,10,0.5)'}} >Elevate Your Skills, Amplify Your Potential <br/>- Our Certification Unlocks New Horizons </h1>
         
          </div>
        </div>
 
    <div class="sidebar-page-container">
        <div class="auto-container">
            <div class="row">
                
             
                <div class="content-side col-xl-9 col-lg-8 order-lg-2">
                    <div class="services-single">
                        <div class="inner-box">
                            <div class="big-image">
                                <img src="assets/images/resource/service-9.jpg" alt="" />
                            </div>
                            <h2 style={{textAlign:'center'}}>Training & Consultancy</h2>
                            <div class="text">
                             <p>
                             The ICT (Information Communication Technology) training and consultancy office of
Admas University is established to provide ICT services training, consultancy and advising
services to the Admas University’s community and to external businesses, organization
and individuals in Ethiopia. The office plans to generate revenue by providing its services
to internal and external customers as a business unit of the university. </p>
<p>The office is mainly
planning to provide specialized training on basic computer skills, programming languages, business intelligence, database platforms, Cisco networking and security. To prepare this
ICT skills training plan, the office has tried to assess the market advantages and selling
points of Admas University, such as, its building (suitability and location). Hence, the main
goal of this business plan is to mobilize resources and conduct specialized IT trainings to
generate revenue and profit for Admas University. </p>
<p>To achieve its business objectives ICT
has prepared this business plan containing the detailed investment requirements for the
project planned to be reviewed by the Executive management of the University for Way
forward. Currently, the office is planning to deliver quality skill training with the specified
course catalog to generate revenue for the University. However, In the future , We will
work hard to be center of excellence in training and create a partnership with training
companies, such as Cisco, Microsoft and Oracle Academies.
                             </p>
                              <div class="two-column">
                                    <div class="row clearfix">
                                        <div class="content-column col-md-6">
                                            <div class="inner-column right-padd">
                                                <h3 style={{textAlign:'center'}}>Why Choose Us</h3>
                                                   <ul class="list-style-four">
                                                    <li>Our ICT training programs are designed to deliver tangible, applicable skills that prepare trainees for success as internationally accredited ICT professionals.</li>
                                                    <li>We are dedicated to driving ICT advances in Ethiopia through innovative training methods and a focus on the latest technologies and industry trends.</li>
                                                    <li>By 2025, we aim to be a large, internationally accredited Information & Communication Technology company, ensuring our training and consulting services meet global standards.</li>
                                                    <li>Our deep understanding of the ICT landscape in this region allows us to provide tailored, contextually-relevant training and consulting services.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="image-column col-md-6">
                                            <div class="image">
                                                <img src={training} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="facts">
                                <h3 style={{textAlign:'center'}}>We Focus on </h3>
                                <div class="text">Our management consulting services focus on our clients most critical issues and opportunities strategy marketing organization operations, technology transformation digital.</div>
                            </div>

                            <div class="featured-blocks">
                                <div class="row">
                           
                                    <div class="featured-block col-md-6">
                                        <div class="featured-inner">
                                            <div class="content">
                                                <div class="icon-box">
                                                    <span class="icon flaticon-work-team"></span>
                                                </div>
                                                <h3><a href="#">Training
                                                </a></h3>
                                                <div class="text">A training program that is designed with the latest innovation in IT field, delivered by highly qualified engineers.</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                    <div class="featured-block col-md-6">
                                        <div class="featured-inner">
                                            <div class="content">
                                                <div class="icon-box">
                                                    <span class="icon flaticon-deadline"></span>
                                                </div>
                                                <h3><a href="#">Consulting
                                                </a></h3>
                                                <div class="text">our experts have a proven track record of helping companies like yours successfully incorporate new technologies to enhance productivity and efficiency.</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                 
                                    <div class="featured-block col-md-6">
                                        <div class="featured-inner">
                                            <div class="content">
                                                <div class="icon-box">
                                                    <span class="icon flaticon-manufacture"></span>
                                                </div>
                                                <h3><a href="#">Certification
                                                </a></h3>
                                                <div class="text">we are dedicated to helping professionals like you achieve industry-recognized certification. By providing convenient access to a wide range of international examinations, we make it easier than ever to assess your skills and knowledge against global standards.</div>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="featured-block col-md-6">
                                        <div class="featured-inner">
                                            <div class="content">
                                                <div class="icon-box">
                                                    <span class="icon flaticon-badge"></span>
                                                </div>
                                                <h3><a href="#">Networking
                                                </a></h3>
                                                <div class="text">Our team of networking experts will work closely with you to assess your current systems, identify areas for improvement, and design a comprehensive solution that elevates your company's performance. From configuring high-speed internet and secure VPNs to implementing advanced cloud networking capabilities, we have the skills and resources to transform your IT infrastructure.</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                          
                            
                        </div>
                    </div>
                </div>
                
             
                <div class="sidebar-side col-xl-3 col-lg-4">
                    <aside class="sidebar">
                        
                    
                        <div class="sidebar-widget sidebar-blog-category">
                            <ul class="blog-cat">
                                <li class="active"><a href="performance-upgrade.html">Trainingand Consultancy</a></li>
                                <li><a href="#">Basic and Advanced MS-office training</a></li>
                                <li><a href="#">Full-Stack Development</a></li>
                                <li><a href="#">Database management</a></li>
                                <li><a href="#">Cyber security and information assurance</a></li>
                                <li><a href="#">Cloud computing and virtualization Training</a></li>
                                <li><a href="#">Digital Marketing</a></li>
                               
                            </ul>
                        </div>
                        
                     
                        <div class="sidebar-widget brochure-widget">
                            
                           
                            
                          
                            
                        </div>

                   
                        <div class="sidebar-widget contact-info-widget">
                            <div class="sidebar-title style-two">
                                <h2>Contact Us </h2>
                            </div>
                            <div class="inner-box">
                                <ul>
                                <div className='text '>
          
          <span className="flaticon-phone social-icon">  </span>
          <span className=" social-icon">Call Us :</span>  0115-50-88-08/10
</div>
<div className="text">
                  Email us : <br />
                  <a href="mailto:contact.contact@autorex.com">info@admasuniversity.edu.et</a>
                </div>

                 </ul>
                            </div>
                        </div>
                        
                    </aside>
                </div>
                
            </div>
        </div>
    </div>
    <ScrollToTopButton/>
    </div>
  )
}

export default Training