import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HeaderLogo from './HeaderLogo';
import HeaderTop from './HeaderTop';
import NavLinks from './NavLink';
import { useAuth } from '../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import loginService from '../../../services/login.service';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { isLogged, setIsLogged, employee } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDropdownEnter = (dropdownName) => {
    setActiveDropdown(dropdownName);
  };

  const handleDropdownLeave = () => {
    setActiveDropdown(null);
  };

  const handleLogout = () => {
    setIsLogged(false);
    loginService.logOut();
    navigate('/');
  };

  return (
    <HeaderContainer>
      <HeaderTop />
      <HeaderWrapper>
        <Logo href="/"><HeaderLogo /></Logo>
        <Navigation className={isMenuOpen ? 'open' : ''}>
          <NavLink href="/">Home</NavLink>
          <DropdownNavLink
            href="#"
            className={activeDropdown === 'about' ? 'open' : ''}
            onMouseEnter={() => handleDropdownEnter('about')}
            onMouseLeave={handleDropdownLeave}
          >
            About
            <Submenu className={activeDropdown === 'about' ? 'open' : ''}>
              <li><a href="/president-message">Message from the President</a></li>
              <li><a href="/history">Company History</a></li>
              <li><a href="/team">Our Team</a></li>
              <li><a href="/about">About Us</a></li>
            </Submenu>
          </DropdownNavLink>
          {/* Rest of the navigation links */}



     
          <DropdownNavLink
            href="#"
            className={activeDropdown === 'academic' ? 'open' : ''}
            onMouseEnter={() => handleDropdownEnter('academic')}
            onMouseLeave={handleDropdownLeave}
          >
            Academic Programs
            <Submenu className={activeDropdown === 'academic' ? 'open' : ''}>
              <li><a href="/postgrad">School of Postgraduate Studies</a></li>
              <li><a href="/undergrad">Undergraduate Academic Programs</a></li>
              <li><a href="/distance">Distance Learning Program</a></li>
              <li><a href="/tvet">TVET Programs</a></li>
            </Submenu>
          </DropdownNavLink>

          <DropdownNavLink
            href="#"
            className={activeDropdown === 'Outreach' ? 'open' : ''}
            onMouseEnter={() => handleDropdownEnter('Outreach')}
            onMouseLeave={handleDropdownLeave}
          >
            Outreach
            <Submenu className={activeDropdown === 'Outreach' ? 'open' : ''}>
              <li><a href="/training">Consultancy & Training</a></li>
              <li><a href="#">Community engagement programs</a></li>
              <li><a href="#">Alumni</a></li>
           
            </Submenu>
          </DropdownNavLink>
       
          <NavLink href="/events">News & Events</NavLink>
          <DropdownNavLink
            href="#"
            className={activeDropdown === 'eservice' ? 'open' : ''}
            onMouseEnter={() => handleDropdownEnter('eservice')}
            onMouseLeave={handleDropdownLeave}
          >
         e-Services
            <Submenu className={activeDropdown === 'eservice' ? 'open' : ''}>
              <li><a href="https://lms.admasuniversity.edu.et/login/index.php" target='_blank'>LMS</a></li>
              <li><a href="http://5.77.63.215:8080/" target='_blank'>Library</a></li>          
              <li><a href="/vacancy">Vacancy</a></li>          
           
            </Submenu>
          </DropdownNavLink>

          <NavLink href="/contact">Contact</NavLink>
</Navigation>

        <MenuButton onClick={toggleMenu}>
          <Hamburger className={isMenuOpen ? 'open' : ''}>
            <span></span>
            <span></span>
            <span></span>
          </Hamburger>
        </MenuButton>
        <div className="search-btn">
          {location.pathname.includes('/ad-adm') && (
            <div className="link-btn">
              {isLogged ? (
                <Link to="/" className="theme-btn btn-style-one blue" onClick={handleLogout}>
                  Log out
                </Link>
              ) : (
                <Link to="/login" className="theme-btn btn-style-one">
                  Login
                </Link>
              )}
            </div>
          )}
        </div>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;




const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0aa8f2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
`;

const Logo = styled.a`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;
 font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;

  @media (max-width: 768px) {
    position: fixed;
    top: 168px;
    right: 0;
    width: 50%;
    height: 100vh;
      background: rgb(8, 8, 81);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    transform: translateX(100%);
    transition: transform 0.3s ease;

    &.open {
      transform: translateX(0);
    }
  }
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-left: 2rem;
  // font-size:1.5rem
  transition: color 0.3s ease;

  &:hover {
    color: #daa520;;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
`;

const DropdownNavLink = styled(NavLink)`
  position: relative;
  cursor: pointer;

  &.open {
    color: #000;
  }
`;

const Submenu = styled.ul`
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #333;

    &:hover {
      background-color: #1ab5f1;
      color: #fff;
    }
  }

  &.open {
    display: block;
  }
`;

const MenuButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Hamburger = styled.div`
  span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #333;
    margin-bottom: 5px;
    transition: all 0.3s ease;

    &:first-child,
    &:last-child {
      transform-origin: center;
    }

    &:first-child {
      transform: ${({ className }) =>
        className === 'open' ? 'translateY(8px) rotate(45deg)' : 'none'};
    }

    &:nth-child(2) {
      opacity: ${({ className }) => (className === 'open' ? '0' : '1')};
    }

    &:last-child {
      transform: ${({ className }) =>
        className === 'open' ? 'translateY(-8px) rotate(-45deg)' : 'none'};
    }
  }
`;

// Styled components for the AdminButton
const AdminButton = styled.a`
  padding: 10px 20px;
  background-color: #daa520;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 20px;
`;