import React from 'react';
import img1 from '../../assets/images/president.png'
import teklay from '../../assets/images/icons/teklay.jpg'
const TeamSection = () => {
  return (
    <section className="team-section">
      <section style={{marginTop:'200px'}}></section>
      <div className="auto-container">
        <div className="top-content">
          <div className="row m-0 justify-content-between">
            <div className="sec-title">
              <h2>Our Team</h2>
            </div>
            <div className="text">
            The dedicated team at Admas University is committed to providing an exceptional educational experience for our students. Our faculty members are renowned experts in their respective fields, bringing a wealth of both academic and real-world experience to the classroom. They are passionate about mentoring the next generation of leaders, innovators, and critical thinkers.
             </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 team-block-one">
            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms">
              <div className="image">
                <img src={img1} alt="" />
                <div className="overlay-box">
                  <ul className="social-links">
                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                    <li><a href="#"><span className="fab fa-google-plus-g"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className="content">
                <h4>Ato Yebraw</h4>
                <div className="designation">General Director</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 team-block-one">
            <div className="inner-box wow fadeInDown" data-wow-duration="1500ms">
              <div className="image">
                <img src={img1} alt="" />
                <div className="overlay-box">
                  <ul className="social-links">
                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                    <li><a href="#"><span className="fab fa-google-plus-g"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className="content">
                <h4>Dr. MOlla</h4>
                <div className="designation">University President</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 team-block-one">
            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms">
              <div className="image">
                <img src={img1} alt="" />
                <div className="overlay-box">
                  <ul className="social-links">
                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                    <li><a href="#"><span className="fab fa-google-plus-g"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className="content">
                <h4>Ato Biru</h4>
                <div className="designation">Acadamic University</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 team-block-one">
            <div className="inner-box wow fadeInDown" data-wow-duration="1500ms">
              <div className="image">
                <img src={img1} alt="" />
                <div className="overlay-box">
                  <ul className="social-links">
                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                    <li><a href="#"><span className="fab fa-google-plus-g"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className="content">
                <h4>Ato Kassahun </h4>
                <div className="designation">Administrative Vice </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 team-block-one">
            <div className="inner-box wow fadeInDown" data-wow-duration="1500ms">
              <div className="image">
                <img src={teklay} alt="" />
                <div className="overlay-box">
                  <ul className="social-links">
                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                    <li><a href="#"><span className="fab fa-google-plus-g"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className="content">
                <h4>Ato Teklay </h4>
                <div className="designation">ICT Vice </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 team-block-one">
            <div className="inner-box wow fadeInDown" data-wow-duration="1500ms">
              <div className="image">
                <img src={img1} alt="" />
                <div className="overlay-box">
                  <ul className="social-links">
                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                    <li><a href="#"><span className="fab fa-google-plus-g"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className="content">
                <h4>Ato Yoseph </h4>
                <div className="designation">Software Team Leader </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;