import React from 'react'
import LoginForm from '../components/LoginForm/LoginForm'
const Login = () => {
  return (
  <div>
    <LoginForm/>
  </div>
  )
}

export default Login