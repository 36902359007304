import React, { useState } from 'react';
import Parallax from 'react-rellax';
import ModalVideo from 'react-modal-video';
import bgImage from '../../assets/images/background/bg-3.jpg';

const VideoSection = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <section className="vi">
   
       
        <div className="video-box">
          <div className="video-btn">
            <a
              href="https://www.youtube.com/watch?v=g64Jk2iotlI"
              className="overlay-link lightbox-image video-fancybox ripple"
              onClick={openModal}
            >
              <i className="flaticon-play"></i>
            </a>
          </div>
          <div className="">Watch intro video <br /> about us</div>
        </div>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="nfP5N9Yc72A"
          onClose={() => setIsOpen(false)}
        />
   
    </section>
  );
};

export default VideoSection;