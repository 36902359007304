import React from 'react';
import image1 from "../../assets/images/resource/image-1.jpg"
import image2 from "../../assets/images/resource/image-2.jpg"
const AboutSection = () => {
  return (
    <section className="about-section" style={{marginTop:'30px'}}>
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-5">
            <div className="image-box">
              <img src={image2} alt="" />
              <img src={image2} alt="" />
              <div className="year-experience" data-parallax='{"y": 30}'>
                <strong>17</strong> years <br /> Experience
              </div>
            </div>
          </div>
          <div className="col-lg-7 pl-lg-5">
            <div className="sec-title">
              <h5>Welcome <span className='social-icon'>@</span> Admas University</h5>
              <h2>We have 17 years experience</h2>
              <div className="text">
                <p>
                Admas University commenced its operation in October 1998 under the name “Admas Business Training Centre.” Then Training Centre then started delivering training services in certain tailor–made, six–month, short–term programmes. By undertaking deep assessments of further training needs and making preparations in terms of the required human and material resources, the centre upgraded itself to a college status as of April 1999. After deep objective assessments of further training needs and making preparations in accordance with the requirements of the Ministry of Education, the College was upgraded to the status of a University College as of March 2007. Admas University has become a fully fledged university as of July 2014 (Hamlie 2006 E.C.).                </p>
                <p>
                 
                </p>
              </div>
              <div className="link-btn mt-40">
                <a
                  href="about.html"
                  className="theme-btn btn-style-one style-two"
                >
                  <span>
                    About Us <i className="flaticon-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;