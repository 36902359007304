import React from 'react'
import ScrollToTopButton from '../pages/ScrollToTopButton'

const Undergrad = () => {
  return (
    <div>
         <div className='undergrad'>
      
  
     
     
         <div class="sidebar-page-container">
             <div class="auto-container">
                 <div class="row">
                     
                  
                     <div class="content-side col-xl-9 col-lg-8 order-lg-2">
                         <div class="services-single">
                             <div class="inner-box">
                                 <div class="big-image">
                                     <img src="assets/images/resource/service-9.jpg" alt="" />
                                 </div>
                                 <div className='sec-title'>
                                 <h2 style={{ textAlign: 'center' }}>The Undergraduate Academic Programs</h2>
                                </div>
                                 <div class="text">
                                     <p>
                                     Expanding its academic study areas every year using needs assessment of the community, currently AU has seven faculties with 14 fields of studies. Accordingly, the following are the high-quality academic fields of study we have in our university.</p>
                                     
                                      
                                 </div>
     
                                 <div class="facts">
                                     <h3 style={{ textAlign: 'center' }}>Admission Criteria</h3>
                                     <div class="text">To be eligible for admission to our university, applicants must meet the following criteria:</div>
                                 </div>
     
                                 <div class="featured-blocks">
                                     <div class="row">
                                         
                                       
                                         <div class="featured-block col-md-6">
                                             <div class="featured-inner">
                                                 <div class="content">
                                                     <div class="icon-box">
                                                         <span class="icon flaticon-work-team"></span>
                                                     </div>
                                                     <h3><a href="#">Duration of the Programs</a></h3>
                                                     <div class="text">
                                                     All Masters Degree programs are set to be two years with four semesters out of which the three semesters are course work while the last semester is a time for the graduation thesis preparation.
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                         
                                       
                                         <div className="featured-block col-md-6">
           <div className="featured-inner">
             <div className="content">
               <div className="icon-box">
                 <span className="icon flaticon-deadline"></span>
               </div>
               <h3><a href="#">Admission Criteria</a></h3>
               <div className="text">
                 <ol>
                   <li>A Bachelor Degree certificate with the grade result set by the Ministry of Education of Somaliland to join University.</li>
                   <li>Two passport size photos</li>
                   <li>Submission of duly filled registration form</li>
                 </ol>
               </div>
             </div>
           </div>
         </div>
                                         
                                  
                                         
                                        
                                       
                                         
                                     </div>
                                 </div>
                                 
                               
                                
                             </div>
                         </div>
                     </div>
                     
                   
                     <div class="sidebar-side col-xl-3 col-lg-4">
                         <aside class="sidebar">
                             

     
                             <div class="sidebar-widget sidebar-blog-category">
                                 <ul class="blog-cat">
                                     <li class="active"><a href="performance-upgrade.html">Undergraduate Academic Programs</a></li>
                                     <li><a href="#">Accounting & Finances</a></li>
                                     <li><a href="#">Business Management</a></li>
                                     <li><a href="#">Marketing Managemnt</a></li>
                                     <li><a href="#">Computer Science</a></li>
                                     <li><a href="#">EDPM</a></li>
                                     <li><a href="#">Economics</a></li>
                                     <li><a href="#">Agricultural Economics</a></li>
                                     <li><a href="#">Rural Dev't & Agri. Extension</a></li>
                                    
                                    
                                 </ul>
                             </div>
                             
                         
                           
     
                           
                             <div class="sidebar-widget contact-info-widget">
                                 <div class="sidebar-title style-two">
                                     <h2>Contact Us</h2>
                                 </div>
                                 <div class="inner-box">
                                     <ul>
                                         <li><span class="icon fas fa-phone"></span>+2510116687671</li>
                                         <li><span class="icon fas fa-paper-plane"></span>https://t.me/AdmasAA</li>
                                     </ul>
                                 </div>
                             </div>
                             
                         </aside>
                     </div>
                     
                 </div>
             </div>
         </div>
         </div>
         <ScrollToTopButton/>
    </div>
  )
}

export default Undergrad