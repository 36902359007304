import React, { useState, useEffect } from 'react';
import VacancyDetail from '../../services/vacancy.service';

const VacancyDetails = () => {
  const [vacancies, setVacancies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(7);

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        const data = await VacancyDetail.getAllVacancies(token);
        setVacancies(data.data);
      } catch (error) {
        console.error('Error fetching vacancies:', error);
      }
    };
    fetchVacancies();
  }, []);

  // Calculate the index of the last and first record on the current page
  const indexOfLastRecord = Math.min(currentPage * recordsPerPage, vacancies.length);
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // Get the records to display on the current page
  const currentRecords = vacancies.slice(indexOfFirstRecord, indexOfLastRecord);

  // Calculate the total number of pages
  const totalPages = Math.ceil(vacancies.length / recordsPerPage);

  // Render the pagination buttons
  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return (
      <div className="pagination">
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={currentPage === pageNumber ? 'active' : ''}
            onClick={() => setCurrentPage(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="table-container">
      {vacancies.length > 0 ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>Vacancy ID</th>
                <th>Title</th>
                <th>Description</th>
                <th>Location</th>
                <th>Salary</th>
                <th>Job Type</th>
                <th>Requirements</th>
                <th>Link</th>
                <th>Posted Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((vacancy, index) => (
                <tr key={vacancy.vacancy_id}>
                  <td>{index + 1}</td>
                  <td>{vacancy.title}</td>
                  <td>{vacancy.description}</td>
                  <td>{vacancy.location}</td>
                  <td>{vacancy.salary}</td>
                  <td>{vacancy.job_type}</td>
                  <td>{vacancy.requirements}</td>
                  <td><a href={vacancy.link}>{vacancy.link}</a></td>
                  <td>{new Date(vacancy.posted_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
                  <td>{new Date(vacancy.end_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {renderPagination()}
        </div>
      ) : (
        <h1 className='no-vacancy'>No Vacancy</h1>
      )}
    </div>
  );
};

export default VacancyDetails;