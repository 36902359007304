import React from 'react'
import campus from "../../../assets/images/resource/project-10.jpg" ;
import mekanisa from '../../../assets/images/icons/mekanisa.jpg';

import mekanisa2 from '../../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../../assets/images/icons/teklay.jpg'
import MekeleStudent from  '../../../assets/images/icons/mekelestudent.jpg'
const Mekanisa = () => {
  return (
    <div>
        
        <div className="banner-slide">
          <img src={mekanisa} alt="Banner 2" className="banner-image" />
          <div className="banner-content">
          <h1>Transforming Lives</h1>
              We believe in providing top quality workmanship and are <br />
              so confident in our level of service that we back it up <br /> with a good quality.
          </div>
        </div>


    <section class="projects-details">
        <div class="auto-container">
            <div class="sec-title style-two">
                <h2>Admas University Mekanisa Campus</h2>
            </div>
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-6">
                        <h4>Message from Campus Dean</h4>
                        <div class="text">
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantitum dolore mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dict eaque ipsa quae ab illo inventore veritatis et quasi architecto. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. </p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                    <figure class="image pht">
    <img src={Dean} alt="" />
    <figcaption>Molla Tsegay (PhD)</figcaption>
  </figure>
                    </div>
                </div>
            </div>
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-6">
                    <div className="sec-title style-two">
              <h2>Accredited Programs</h2>
            </div>
            
                    <div className="col-md-6">
                <h5 className='programs' >Master's Degree</h5>
                <ul className="list">
                  <>

                    <ul className="sub-list ">
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Accounting & Finance</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Computer Science</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Master of Business Adiministration</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Project Management</div></div></li>
                    </ul>
                  </>
                  <h5 className='programs' >Bachler's Degree</h5>
                  <>
                    <ul className="sub-list">
                    <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Accounting & Finance</div></div></li>
                    <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Business Management</div></div></li>
                    <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Marketing Managemnt</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Computer Science</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>EDPM</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Economics</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Agricultural Economics</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Rural Dev't & Agri. Extension</div></div></li>                  
                    
                    </ul>
                  </>
                  <div className='programs' >TVET</div>
                  <>
                    <ul className="sub-list">
                   
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Business & Finance</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Information Technology</div></div></li>
                      <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Hotel & Tourism</div></div></li>
                
                    
                    </ul>


                  </>
             
                </ul>
              </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="image"><img src={mekanisa2} alt=""/></div>
                    </div>
                </div>
            </div>
           
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-6">
                        <h4>Student Life @ Mekanisa Campus</h4>
                        <div class="text">
                            Reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco  proident, sunt in culpa qui officia deserunt mollit anim id est laborum.  
                        </div>
                     
                    </div>
                    <div class="col-lg-6">
                        <div class="image"><img src={MekeleStudent}alt=""/></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Mekanisa