// Import from the env 
const api_url = process.env.REACT_APP_API_URL;




const createCustomer = async (consentData, accessToken) => {
  const response = await fetch(`${api_url}/api/customerMessage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
    body: JSON.stringify(consentData),
  });
  return response;
};






// Export all the functions 
const customerService = {
  
   
    createCustomer
}
export default customerService; 