import getAllAnnouncements  from '../../services/announcement.service';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BlogSection = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchAnnouncements = () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    getAllAnnouncements.getAllAnnouncements(token)
      .then((response) => {
        setAnnouncements(response.data);
      })
      .catch((error) => {
        console.error('Error fetching announcements:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const handleAnnouncementClick = (announcement) => {
    navigate('/blogdetails', { state: announcement });
  };

  return (
    <section className="blog-section">
      <div className="auto-container">
        <div className="top-content">
          <div className="m-0 row justify-content-between align-items-center">
            <div className="sec-title style-two">
              <h2>Latest Announcement</h2>
            </div>
            <div className="link-btn">
              <a href="/allannouncement" className="theme-btn">
                View All Announcement
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          {announcements.slice(0, 3).map((announcement, index) => (
            <div key={index} className="col-lg-4 news-block-one">
              <div className="inner-box">
                <div className="image">
                  <a onClick={() => handleAnnouncementClick(announcement)}>
                    <img src={announcement.image_url || '../../assets/images/resource/news-1.jpg'} alt="" />
                  </a>
                </div>
                <div className="lower-content">
                  <div className="meta-info">
                    <a onClick={() => handleAnnouncementClick(announcement)}>{announcement.title}</a>
                    <a onClick={() => handleAnnouncementClick(announcement)}>{announcement.category}</a>
                  </div>
                  <h3>
                    <a onClick={() => handleAnnouncementClick(announcement)}>{announcement.title}</a>
                  </h3>
                  <div className="link-btn">
                    <a onClick={() => handleAnnouncementClick(announcement)} className="theme-btn">
                      <i className="flaticon-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;