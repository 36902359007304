import { useLocation } from 'react-router-dom';
const api_url = process.env.REACT_APP_API_URL;

function News() {
  const { state } = useLocation();

  if (!state) {
    // Handle the case where the state is null
    return <div>Loading...</div>;
  }

  const { title, content, created_at, author, image } = state;

  return (
    <section className="blog-section" style={{ marginTop: '200px' }}>
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-12">
            <div className="lower-content">
              <div className="date">{formatDate(created_at)}</div>
              <ul className="meta-info">
                <li>By: {author}</li>
                <li></li>
              </ul>
              <div className="inner-box">
              {image && (
                <img
                  src={`${api_url}/${image}`}
                  alt={title}
                  style={{ maxWidth: 'auto', height: 'auto' ,  float: 'right'}}
                />
              )}
            </div>
              <h3><a href="">{title}</a></h3>
              <div className="text">{content}
          </div>
          {/* <div className="col-lg-6 news-block-two"> */}
            
          {/* </div> */}
          </div>
            </div>
        </div>
      </div>
    </section>
  );
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
}

export default News;