import { useState, useEffect } from 'react';
import announcement from '../../services/announcement.service';

const api_url = process.env.REACT_APP_API_URL;

const AnnouncementEvents = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await announcement.getAllAnnouncements();
        setAnnouncements(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!announcements || announcements.length === 0) {
    return <div>No announcements found.</div>;
  }

  return (
    <div className="blog-section" style={{ minHeight: '100vh', marginTop: '200px' }}>
      <div className="auto-container">
        <div className="row">
          {announcements.map((announcement, index) => (
            <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
              <div className="inner-box">
                <h3 style={{textAlign:'center',margin:'20px auto'}}>{announcement.title}</h3>
                {announcement.image && (
                  <img
                    src={`${api_url}/${announcement.image}`}
                    alt={announcement.title}
                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                  />
                )}
                <div className="lower-content">
                  <div className="date">{new Date(announcement.created_at).toLocaleDateString()} <span style={{fontSize:'1rem'}}> By : {announcement.author}</span></div>
                  <h5></h5>
                  <div>
                    {announcement.content.split(' ').slice(0, 30).join(' ')}
                    {announcement.content.split(' ').length > 30 && (
                      <button
                        style={{ marginLeft: '10px', color: 'white',backgroundColor: '#31a8db'}}
                        onClick={() => {
                          const contentDiv = document.getElementById(`content-${index}`);
                          contentDiv.style.display = 'block';
                          const readMoreBtn = document.getElementById(`read-more-${index}`);
                          readMoreBtn.style.display = 'none';
                        }}
                        id={`read-more-${index}`}
                      >
                        Read More >>
                      </button>
                    )}
                  </div>
                  <div id={`content-${index}`} style={{ display: 'none' }}>
                    {announcement.content}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementEvents;