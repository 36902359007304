import React from 'react';
import { useLocation } from 'react-router-dom';
const api_url = process.env.REACT_APP_API_URL;

const BlogDetails = () => {
  const location = useLocation();
  const newsItem = location.state;

  return (

<>

<section class="blog-details" style={{minHeight: '100vh' ,marginTop: '200px'}}>
  <div class="auto-container">
    <div class="row">
      <div class="col-lg-8 content-side">
        <div class="blog-details-content">
          <div class="upper-box" style={{display: 'flex', alignItems: 'center'}}>
            <div class="col-lg-6 news-block-two" style={{flex: '0 0 50%'}}>
              <div class="inner-box">
                {newsItem.image && (
                  <img
                    src={`${api_url}/${newsItem.image}`}
                    alt={newsItem.title}
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                )}
              </div>
            </div>
            <div class="info" style={{flex: '0 0 50%'}}>
              <ul class="post-meta">
                <h3>{newsItem.title}</h3>
                <li>{new Date(newsItem.created_at).toLocaleDateString()}</li>
              </ul>
            </div>
          </div>
          <div class="content-box">
            <p>{newsItem.content}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 sidebar-side">
       
      </div>
    </div>
  </div>
</section>
</>

  );
};

export default BlogDetails;