import React from 'react'

import BannerSlider from "../components/Banner/BannerSlider";
import LatestNewsTransition from "../components/LatestNewsTransition";
import ServiceSection from "../components/ServicesSection.jsx";
import WhyChooseUs from "../components/WhyChooseUs"
import TeamSection from "../components/TeamSection.jsx";
import VideoSection from "../components/VideoSection";
import AboutSection from "../components/AboutSection.jsx";
import BlogSection from '../components/BlogSection.jsx';
import UniversityInfo from '../pages/UniversityInfo.js';
import ScrollToTopButton from "../pages/ScrollToTopButton.jsx";

const Home = () => {
  return (
 
     <div>
      <BannerSlider/>
      <LatestNewsTransition/>
      <AboutSection/>
      <ServiceSection/>
      <WhyChooseUs/>
      <BlogSection/>
      <UniversityInfo/>

      <VideoSection/>
      <ScrollToTopButton/>
      
     </div>  
   
     
      
    
  
  )
}

export default Home