// Import from the env 
const api_url = process.env.REACT_APP_API_URL;

const createAnnouncement = async (formData, image, loggedInEmployeeToken) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'x-access-token': loggedInEmployeeToken
    },
    body: new FormData()
  };

  requestOptions.body.append('title', formData.title);
  requestOptions.body.append('content', formData.content);
  requestOptions.body.append('author', formData.author);
  requestOptions.body.append('image', image);

  const response = await fetch(`${api_url}/api/employee/announcement`, requestOptions);
  return response;
};


// A function to send get request to get all announcements
const getAllAnnouncements = async () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // 'x-access-token': token
    }
  };
  const response = await fetch(`${api_url}/api/employee/announcement`, requestOptions);
  if (!response.ok) {
    throw new Error(`HTTP error ${response.status}`);
  }
console.log(response)
  return response.json();
};



// A function to send delete request to delete an announcement
const deleteAnnouncement = async (announcementId, token) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    }
  };
  const response = await fetch(`${api_url}/api/employee/announcement/${announcementId}`, requestOptions);
  return response;
}

// Export all the functions 
const employeeService = {
  createAnnouncement,
  getAllAnnouncements,
  deleteAnnouncement
}
export default employeeService;