import React from 'react'
import ScrollToTopButton from '../pages/ScrollToTopButton'

const History = () => {
  return (
    <div className='history'>

        <section class="history-section">
            <div class="auto-container">
                <div class="history-block">
                    <div class="years">1998</div>
                    <div class="content">
                        <h4>Admas University was founded.</h4>
                        <div class="text">Admas University was founded in October 1998, initially operating as the "Admas Business Training Centre." The Training Centre began by delivering specialized, six-month short-term programs, catering to the evolving training needs of the community.</div>
                    </div>
                </div>
                <div class="history-block">
                    <div class="years">1999</div>
                    <div class="content">
                        <h4>Transitioning to the status of a College.</h4>
                        <div class="text">In April 1999, the Training Centre underwent a significant upgrade, transitioning to the status of a College. This marked the beginning of a period of steady growth and development. The institution continued to expand its offerings and enhance its resources, both human and material.</div>
                    </div>
                </div>
                <div class="history-block">
                    <div class="years">2007</div>
                    <div class="content">
                        <h4>Accredited as a "University College."</h4>
                        <div class="text">By March 2007, the College had reached a new milestone, being accredited as a "University College." This elevated status allowed Admas to broaden its academic scope and solidify its position as a leading provider of higher education.</div>
                    </div>
                </div>
                <div class="history-block">
                    <div class="years">2014</div>
                    <div class="content">
                        <h4>Full accreditation as a "UNIVERSITY"</h4>
                        <div class="text">After seven and a half years of distinguished service as a University College, Admas University achieved a remarkable feat in July 2014. The institution was granted full accreditation as a "UNIVERSITY" by the Ministry of Education of the Federal Democratic Republic of Ethiopia. </div>
                     
                        <div class="text">
                            <p>Since its establishment, Admas University has graduated tens of thousands of students across various fields of study, including TVET, post-graduate diploma, and degree programs. The university's campuses are strategically located, with six campuses in Addis Ababa, one in Bishoftu, and one in Mekelle.</p>
                            <p>In its ongoing pursuit of expanding quality services to the community, Admas University has extended its reach beyond the borders of Ethiopia. The university now operates seven campuses in foreign lands, with four campuses in Hargeissa, Somaliland, and three campuses in Puntland.</p>
                            <p>Furthermore, the College of Distance Education of Admas University has established 14 fully accredited branches, further enhancing its ability to deliver accessible and flexible educational opportunities.</p>
                            <p>As Admas University continues to grow and evolve, its commitment to excellence and its unwavering dedication to serving the educational needs of its students remain at the core of its mission. The university's remarkable journey serves as a testament to its resilience, innovation, and the profound impact it has had on the lives of countless individuals and communities.</p>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTopButton/>
        </section>


    </div>
  )
}

export default History