import React from 'react';
import photo from "../../assets/images/president.png"
const PresidentMessage = () => {
 
      

  return (
   <>
 
        <section class="about-section-president" >
            <div class="auto-container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="content">
                        <div className="sec-title">
                            <h3 >Message from the President of   <br/> Admas University</h3>
                           </div>
                            <div class="text">
                                <p>Bring to the table win-win survival strategies to ensure proactive domination. At the
                                    end of the day, going forward, a new normal that has evolved from generation X is on
                                    the runway heading towards a streamlined cloud solution. User generated content in
                                    real-time will have multiple touchpoints for offshoring.</p>
                                <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta
                                    test. Override the digital divide with additional clickthroughs from DevOps.
                                    Nanotechnology immersion along the information heading towards a streamlined cloud
                                    solution. User generated content in real-time will have multiple.</p>
                                <p>Summarizing the achievements Admas University made so far, it has managed 
                                  to attain its mission and vision and has proved itself as a major pillar in 
                                  the overall development of Somaliland. The university would like to express its 
                                  gratitude to all stakeholders who have made it possible for the university to accomplish 
                                  all the remarkable achievements.</p>
                                  <br/>
                                  <p>Sincerely</p>
                                  <p>Molla Tsegay (PhD)</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
  <figure class="image pht">
    <img src={photo} alt="" />
    <figcaption>Molla Tsegay (PhD)</figcaption>
  </figure>
</div>
                </div>
            </div>
        </section>
   
   
   </>
  );
};

export default PresidentMessage;